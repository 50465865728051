import React, {useEffect, useState} from 'react'
import { ImageItemProps } from './ImageDatas';
import ImageItem from './ImageItem';

interface ImageBoxProps {
  items: ImageItemProps[];
  widthTd: number;
  callback: (value: string) => void;
}

const ImageBox: React.FC<ImageBoxProps> = ({ items, widthTd, callback }) => {
  const [show, setShow] = useState(false);
  const [itemChanged, setChange] = useState(false);
  const [currentIndex, setIndex] = useState(-1);
  const [itemTime, setTime] = useState(0);
  const [itemDelay, setDelay] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const totalItems = items.length;

  useEffect(() => {
    const time = items.reduce((total, item) => {
      return total + item.duration + item.delayToNextItem + item.initialDelay;
    }, 0);
    setTotalTime(time * 1000);
    // console.log("Total time: ", totalTime);
    changeItem();
  }, []);

  useEffect(() => {
    if (itemChanged) {
      setChange(false);
      setShow(true);
      setTimeout(() => setShow(false), itemTime);
      // console.log(`Start delay of ${items[currentIndex].file} with ${itemDelay + itemTime}`);
      setTimeout(() => changeItem(), itemDelay + itemTime);
      
    }
  }, [itemChanged, currentIndex]);

  const changeItem = () => {
    let i = currentIndex;
    i = (i + 1) % totalItems;
    setIndex(i);

    const ci = items[i];
    const currentPlayTime = (ci.initialDelay + ci.duration) * 1000;
    const delayTime = ci.delayToNextItem * 1000;
    setTime(currentPlayTime);
    setDelay(delayTime);
    setChange(true);
    
    // console.log(`ID ${i} - Changed item to ${ci.file}, play time: ${currentPlayTime}`);
  }

  return (
    show ?
      <>
        {items.map((item, index) => (
          <div id={item.file} key={index}>
            {currentIndex === index && <ImageItem item={item} widthTd={widthTd} callback={callback} />}
          </div>
        ))}
      </>
      :
      <div />
  )
}

export default ImageBox;

/* DEPRECATED CODE */

// interface Props {
//   widthTd: number;
//   height: number;
//   gif1: string;
//   gif2: string;
//   time1: number;
//   time2: number;
//   className1: string;
//   className2: string;
//   timeShow?: number;
// }

// export default function ImageBox(props: Props) {
//   const {widthTd, height, gif1, gif2, time1, time2, className1, className2, timeShow} = props;
//   const [show, setShow] = useState(false);
//   const [animation, setAnimation] = useState('fadeInOut 0.5s ease-in-out');

//   const _renderImage = (src: string, name: string) => {
//     if(!src){
//       return;
//     }
//     const animationData = require(`../../../assets/Json/${src}.json`);
//     return (
//       <div
//         className={name}
//       >
//         <Lottie
//           options={getSettingGifDefault(animationData)}
//           style={{
//             animation: animation,
//             borderRadius: 10,
//           }}
//           width={'100%'}
//           height={height - 20}
//           isClickToPauseDisabled
//         />
//       </div>
//     )
//   };

//   useEffect(() => {
//     if (timeShow) {
//       setTimeout(() => setShow(true), timeShow)
//     } else {
//       setShow(true)
//     }
//   }, []);

//   const changeAnimation = (value: string) => {
//     setAnimation(value)
//   };
//   return (
//     show ?
//       <td className={'td-table-container'} style={{width: widthTd, height: widthTd}}>
//         <ImageItem
//           element1={_renderImage(gif1, className1)}
//           element2={_renderImage(gif2, className2)}
//           time1={time1}
//           time2={time2}
//           changeAnimation={changeAnimation}
//         />
//       </td>
//       :
//       <td className={'td-table-container'} style={{width: widthTd, height: widthTd}}/>
//   )
// }
