import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import SiderLeft from '../SiderLeft';
import SiderRight from '../SiderRight';
import { Outlet } from 'react-router-dom';
import TitleDesktop from '../TitleDesktop';
import CustomGridReveal from '../../../ultils/CustomGridReveal';

export default function ContentDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);

  return (
    <div className='content-container'>
      <div className='title-panel'>
        <TitleDesktop />
        <CustomGridReveal />
      </div>
      <div className='content-panel scroll-trigger content-base'
        style={{
          height: window.innerHeight,
          // marginTop: heightContent * 0.1,
          // paddingBottom: heightContent * 0.1,
          // width: widthContent,
        }}
      >
        <div className='content-home-container'
          style={{
            marginTop: (window.innerHeight - heightContent) * 0.5 + heightContent * 0.1,
            marginBottom: (window.innerHeight - heightContent) * 0.5 + heightContent * 0.1,
            height: heightContent * 0.8,
            width: widthContent,
          }}
        >
          <SiderLeft />
          <div className={'content-home'} style={{
            width: heightContent * 0.8 * 4 / 3 + 80,
            // marginLeft: width,
            // marginRight: width,
          }}>
            <Outlet></Outlet>
          </div>
          <SiderRight />
        </div>
      </div>
    </div>
  )
}
