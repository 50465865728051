import React, { useEffect } from 'react';
import { Layout } from "antd";
import TextFooter from '../../../component/Desktop/TextFooter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import Lottie from 'react-lottie';
import { getSettingGifDefault } from '../../../ultils/getSettingGif';
import * as animationData from '../../../assets/Json/FLOATINGMAN.json';
import { handleWheel } from '../../../ultils/common';

const { Footer } = Layout;

export default function FooterDesktop() {
  const widthContent = useSelector((state: RootState) => state.sizeContent.width);
  const heightContent = useSelector((state: RootState) => state.sizeContent.height);
  const lineWidth = `${widthContent * 0.465}px`;
  const topOffset = (window.innerHeight - heightContent) * 0.5;
  const leftOffset = (window.innerWidth - widthContent) * 0.5;

  useEffect(() => {
    const container = document.querySelector('.footer-desktop-container') as HTMLElement | null;

    container?.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      container?.removeEventListener('wheel', handleWheel);
    }
  }, []);

  const _footer = () => {
    return (
      <div className="footer-info-container"
        style={{
          height: heightContent / 10,
          width: widthContent,
        }}>
        <div className={'footer-info-copyright'}>
          <TextFooter text={'CONCOCTING CHAOS'} />
          <TextFooter text={'TRUST THE PROCESS'} />
        </div>
        <div className="line-footer"
          style={{
            width: lineWidth
          }} />
        {_renderGif()}
        <div className="line-footer"
          style={{
            width: lineWidth
          }} />
      </div>
    )
  }

  const _renderGif = () => {
    return (
      <div
        className={'gif-image'}
      >
        <Lottie
          options={getSettingGifDefault(animationData)}
          width={'60%'}
          height={'auto'}
          isClickToPauseDisabled
        />
      </div>
    )
  };

  return (
    <Footer
      className='footer-desktop-container'
      style={{
        height: heightContent / 10 + topOffset,
        width: widthContent,
        '--offset-from-left': `${leftOffset}px`,
      } as React.CSSProperties}
    >
      {_footer()}
      {/* <div className='footer-desktop-background'
        style={{
          height: topOffset
        }} /> */}
    </Footer>
  )
}
