import React, { useEffect, useState } from 'react';
import { ImageItemProps } from './ImageDatas';
import Lottie from 'react-lottie';
import { getSettingGifDefault } from '../../../ultils/getSettingGif';

export interface ImageItemProp {
  item: ImageItemProps;
  widthTd: number;
  callback: (value: string) => void;
}

interface sizeWithClass {
  size: number,
  className: string,
}

const data: Record<number, sizeWithClass> = {
  0: { size: 1, className: "img-gif-first" },
  1: { size: 1, className: "img-gif-first2" },
  2: { size: 2, className: "img-gif-first3" },
  3: { size: 2, className: "img-gif-first4" }
};

// Renders the image with specific width and height
const ImageItem: React.FC<ImageItemProp> = ({ item, widthTd, callback }) => {
  const { file, duration, initialDelay, delayToNextItem, link, row, column, dim } = item;
  const [animation, setAnimation] = useState('fadeIn 0.5s ease-in-out');
  const [show, setShow] = useState(false);
  const [updateAnim, setUpdateAnim] = useState(false);
  const fadeDuration = 500;

  useEffect(() => {
    if (initialDelay) {
      setTimeout(() => setShow(true), initialDelay * 1000);
    } else {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (updateAnim) {
      const str = 'fadeOut 0.5s ease-in-out'
      setAnimation(str);
      setTimeout(() => setShow(false), fadeDuration);
      // console.log("Hide", file);
    }
  }, [updateAnim]);

  useEffect(() => {
    if (show && !updateAnim) {
      // console.log("Show", file);
      setTimeout(() => setUpdateAnim(true), duration * 1000 - fadeDuration);
    }
  }, [animation, show]);


  if (!file) { // If there is no source file
    return;
  }
  const animationData = require(`../../../assets/Json/${file}.json`);

  return (
    show ?
      <div
        className={data[dim].className}
        onClick={() => callback(link)}
      >
        <Lottie
          options={getSettingGifDefault(animationData)}
          width={'100%'}
          height={widthTd * data[dim].size - 20}
          isClickToPauseDisabled
          style={{
            animation: animation,
            borderRadius: 10,
          }}
        />
      </div> : <div />
  )
}

export default ImageItem;

/* DEPRECATED CODE */

// interface Props {
//   element1: React.ReactNode;
//   element2: React.ReactNode;
//   time1: number;
//   time2: number;
//   changeAnimation: (value: string) => void;
// }

// export default function ImageItem(props: Props) {
//   const { element1, element2, time1, time2, changeAnimation } = props;
//   const totalTime = time1 + time2 + 10;
//   const [show1, setShow1] = useState(false);
//   const [show2, setShow2] = useState(false);

//   useEffect(() => {
//     changeImageShow();
//     const intervalId = setInterval(changeImageShow, (totalTime) * 1000);
//     return () => clearInterval(intervalId);
//   }, []);

//   const changeImageShow = () => {
//     initAnimation();
//     setTimeout(() => change1(), time1 * 1000);
//     setTimeout(() => change2(), (time1 + 5) * 1000);
//     setTimeout(() => change1(), (time1 + 5 + time2) * 1000);
//   };

//   const initAnimation = () => {
//     setShow1(true);
//     setShow2(false);
//     changeAnimation('fadeIn 0.5s ease-in-out');
//   };

//   const change1 = () => {
//     changeAnimation('fadeOut 0.5s ease-in-out');
//     setTimeout(() => {
//       setShow1(false);
//       setShow2(false);
//     }, 500)
//   };

//   const change2 = () => {
//     setShow1(false);
//     setShow2(true);
//     changeAnimation('fadeIn 0.5s ease-in-out');
//   };

//   return (
//     <>
//       {show1 && element1}
//       {show2 && element2}
//     </>
//   )
// }