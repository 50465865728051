import './footer-mobile.scss';
import {LeftOutlined, RightOutlined, WarningOutlined} from "@ant-design/icons";
import {PAGE_MOBILE} from "../../../constants";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

interface Props {
  footerType: number;
  handlePrevStep: () => void;
  handleNextStep: () => void;
  goToLast: () => void;
  goToDetail?: () => void;
}

const FooterMobile = (props: Props) => {
  const {footerType, handlePrevStep, handleNextStep, goToDetail, goToLast} = props;
  const listProject = useSelector((state: RootState) => state.projectList);

  const _renderFooterIntro = () => {
    return (
      <div className={`footer-intro-mobile`}>
        <p className={`text-footer-mobile`}>
          <LeftOutlined
            className="icon-transfer"
            onClick={handlePrevStep}
          />
          {'Experimentation in progress...'}
          <RightOutlined
            className="icon-transfer"
            onClick={handleNextStep}
          />
        </p>
      </div>
    )
  };

  const _renderFooterLoading = () => (
    <div className={`footer-intro-mobile`}>
      <p className={`text-footer-mobile`}>
        A small lab with big experiments.
        <RightOutlined
          className="icon-transfer"
          onClick={handleNextStep}
        />
      </p>
    </div>
  );

  const _renderFooterProject = () => (
    <div className={`footer-intro-mobile`}>
      <div className={`text-footer-mobile`}>
        <LeftOutlined
          className="icon-transfer"
          onClick={handlePrevStep}
        />
        <p onClick={goToLast} className={'text-create-mobile'}>
          LET'S CREATE CHEMISTRY
        </p>
        <RightOutlined
          className="icon-transfer"
          onClick={handleNextStep}
        />
      </div>
    </div>
  );

  const _renderFooterReach = () => {
    return (
      <div className={`footer-intro-mobile`}>
        <p className={`text-footer-mobile`}>
          <LeftOutlined
            className="icon-transfer"
            onClick={handlePrevStep}
          />
          <RightOutlined
            className="icon-transfer"
            onClick={handleNextStep}
          />
        </p>
      </div>
    )
  }

  const _renderFooter = () => {
    switch (footerType) {
      case PAGE_MOBILE.LOADING:
        return _renderFooterLoading();
      case PAGE_MOBILE.INTRO:
        return _renderFooterIntro();
      case PAGE_MOBILE.REACH + listProject.length - 1 :
        return _renderFooterReach();
      default:
        return _renderFooterProject();
    }
  };

  return (
    <div className={'footer-mobile-container'}>
      {_renderFooter()}
    </div>
  )

};

export default FooterMobile;
